<template>
    <div class="web_top">
        <h3>你好，欢迎使用数农在线财务管理系统</h3>
        <div class="web_top_right">
            <a-button type="block" @click="showinteraction('暂未开放')">
                <UserOutlined />用户
            </a-button>
            <a-button type="block" @click="showinteraction('暂未开放')">
                <CustomerServiceOutlined />在线客服
            </a-button>
            <a-button type="block" @click="logOutFun">
                <LogoutOutlined />安全退出
            </a-button>
        </div>
    </div>
</template>

<script lang="ts">
import router from "@/router";
import { message } from 'ant-design-vue';
import {
    LogoutOutlined,
    CustomerServiceOutlined,
    UserOutlined
} from '@ant-design/icons-vue';
export default {
    components: {
        LogoutOutlined,
        CustomerServiceOutlined,
        UserOutlined
    },
    setup() {
        const logOutFun = () => {
            localStorage.clear();
            sessionStorage.clear();
            router.push('/')
        }



        const showinteraction = (text: string) => {
            message.info(text)
        }


        return {
            logOutFun,
            showinteraction
        }
    }
}



</script>

<style lang="less" scoped>
.web_top {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    h3 {
        color: #000;
    }
}
</style>