import axios from 'axios';
import { message } from 'ant-design-vue';
import store from '@/store';
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };


const request = (url: string, text: string, data: any, loading: boolean = true) => {
  const baseUrl = `https://cw.jibeimall.com/app/index.php?i=4&c=entry&m=ewei_shopv2&do=mobile&r=${url}&app=1`;



  if (loading) store.commit('SPINNING_FUN', true);


  // message.loading({ content: '加载中...' });
  let method = text.toUpperCase();
  return new Promise((resolve, reject) => {

    axios({
      method: method,
      url: baseUrl,
      data: data,
      headers,
    }).then(res => {
      console.log('请求成功', res);
      resolve(res.data);
      store.commit('SPINNING_FUN', false);
      if (res.data.error === 0) {
        if (res.data.message) message.success(res.data.message);
      } else {
        if (res.data.message) message.error(res.data.message);
      }


      if (res.data.header) {
        if (res.data.header.code === "000000") {
          if (res.data.header.msg) message.success(res.data.header.msg)
        } else if (res.data.header.code) {
          if (res.data.header.msg) message.error(res.data.header.msg)
        }
      }


    })
      .catch(err => {
        console.log('请求失败');
        store.commit('SPINNING_FUN', false);
        reject(err); //失败
      })
  })
}
export default request;
