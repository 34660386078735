import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';



import home from '../views/home/index.vue';
import bankBusiness from '../views/home/bankBusiness/index.vue';
import settings from '../views/home/settings/index.vue';
import member from '../views/home/member/index.vue'
import View from '../views/index.vue'
import rolemanagement from '@/views/home/roleManagement/index.vue'



import login from '../views/login/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/',
    name: 'View',
    component: View,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: home,
      },
      {
        path: '/bankBusiness',
        name: 'BankBusiness',
        component: bankBusiness
      },
      {
        path: '/settings',
        name: 'Settings',
        component: settings
      },
      {
        path: '/rolemanagement',
        name: 'RoleManagement',
        component: rolemanagement
      },
      {
        path: '/member',
        name: 'Member',
        component: member
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: login
  }
]






const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

import { message } from 'ant-design-vue';

router.beforeEach((to, from, next) => {
  let user = localStorage.getItem('user') || sessionStorage.getItem('user')
  if (to.name === 'Login') {
    console.log('Login');
    next();
  } else {
    if (user) {
      next();
    } else {
      message.error('请先登录')
      router.push({ path: '/' })
    }
  }
})


export default router
