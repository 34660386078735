<template>
    <div class="bankBusiness">
        <div class="bankBusiness_top">
            <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
        </div>
        <div class="bankBusiness_box">
            <div class="bankBusiness_box_item" v-if="current[0] === '0'">
                <transfer-of-money />
            </div>
            <div class="bankBusiness_box_item" v-if="current[0] === '1'">
                <Balance-enquiry />
            </div>
            <div class="bankBusiness_box_item" v-if="current[0] === '2'">
                <Detailed-inquiry />
            </div>
            <div class="bankBusiness_box_item" v-if="current[0] === '3'">
                <Receipt-query />
            </div>
            <!--  <div class="bankBusiness_box_item" v-if="current[0] === '4'">
                <a-empty />
            </div> -->
        </div>
    </div>
</template>

<script lang="ts" setup>

import TransferOfMoney from './TransferOfMoney.vue';
import BalanceEnquiry from './balance_enquiry.vue';
import DetailedInquiry from './DetailedInquiry.vue';
import ReceiptQuery from './ReceiptQuery.vue'
import { ref, reactive, onMounted } from 'vue';
import { MenuProps } from 'ant-design-vue';


onMounted(() => {

})

import store from '@/store';
let user: any = ref(store.state.user);
user.value = JSON.parse(user.value);
console.log(user.value);



const current = ref<string[]>(['0']);



//初始tab选中0
//tab数据
const items = reactive([
    {
        key: '0',
        label: '转账'
    },
    {
        key: '1',
        label: '内部明细',
        disabled: user.value.roule === 0 ? false : true,
    },
    {
        key: '2',
        label: '明细查询',
        disabled: user.value.roule === 0 ? false : true,
    },
    // {
    //     key: '3',
    //     label: '电子回单查询',
    // },
    // {
    //     key: '4',
    //     label: '交易状态',
    // },
]);



</script>

<style lang="less" scoped>
.bankBusiness {
    width: 100%;
    height: calc(100vh - 60px);

    display: flex;
    flex-direction: column;

    .bankBusiness_top {
        height: 46px;
    }

    .bankBusiness_box {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        padding: 50px;

        .bankBusiness_box_item {
            width: 100%;
            height: 100%;
        }


    }


}
</style>