<template>
  <nav>
    <!-- <router-view  /> -->
    <router-view />
  </nav>
</template>
<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('en');

const handleWheel = (event: { ctrlKey: any; deltaY: number; preventDefault: () => void; }) => {
  // 检查是否按下了Ctrl键
  if (event.ctrlKey && event.deltaY !== 0) {
    event.preventDefault(); // 阻止默认的滚轮行为
  }
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li,
ul,
ol {
  list-style: none;
}

div {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
