<template>
    <div class="ReceiptQuery">

        
    </div>
</template>

<script setup lang="ts">
import { api_cw_hd_check } from '@/api/home'
import { onMounted, ref } from "vue";

import store from '@/store';

let user: any = ref(store.state.user);
user.value = JSON.parse(user.value)

let accNo = ref<string>('');
let retNo = ref<string>('');
onMounted(() => {
    console.log(user.value);
})



const api_cw_hd_checkFun = async () => {
    const up = {
        openid: user.value.openid,
        accNo: accNo.value,
        retNo: retNo.value
    }
    let data = await api_cw_hd_check(up);
    console.log(data);

}
</script>

<style lang="less" scoped>
.ReceiptQuery {
    width: 100%;
    height: 100%;
}
</style>