import { createStore } from 'vuex'

interface STATE {
  spinning: boolean,
  user: any
}

export default createStore({
  state: <STATE>{
    spinning: false,
    user: sessionStorage.getItem('user') ? sessionStorage.getItem('user') : ''
  },
  getters: {
  },
  mutations: {
    SPINNING_FUN(state, flag) {
      state.spinning = flag;
    }
  },
  actions: {

  },
  modules: {
  }
})
