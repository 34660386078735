<template>
    <div class="rolemanagement">


        <a-card style="width: 100%;height: 100%;">
            <template #extra>
                <a-form :model="FormData" layout="inline" @finish="onFinish('')">
                    <a-form-item name="mobile" label="手机号" :rules="rules.mobile">
                        <a-input v-model:value="FormData.mobile" placeholder="请输入手机号" />
                    </a-form-item>
                    <a-form-item name="pwd" label="密码" :rules="rules.pwd">
                        <a-input v-model:value="FormData.pwd" placeholder="请输入密码" />
                    </a-form-item>
                    <a-form-item label="权限">
                        <a-select ref="select" v-model:value="FormData.roule" style="width: 150px">
                            <a-select-option value="0" disabled>超级管理员</a-select-option>
                            <a-select-option value="1">制单员</a-select-option>
                            <!-- <a-select-option value="2">打款员</a-select-option> -->
                            <a-select-option value="3">审核员</a-select-option>
                            <a-select-option value="4">审查员</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button html-type="submit" type="primary">注册子账号</a-button>
                    </a-form-item>
                </a-form>
            </template>


            <div class="rolemanagement_box">
                <a-table class="components-table-demo-nested" :columns="columns" :data-source="list"
                    :scroll="{ y: 500 }" :pagination="false">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'Controls'">
                            <a-space warp>
                                <a-button type="primary" @click="EditingFun(record)">编辑</a-button>
                                <a-button type="primary" danger @click="DeleteFun(record)">删除</a-button>
                            </a-space>
                        </template>
                    </template>
                </a-table>
            </div>
            <a-pagination style="margin-top: 20px;" v-model:current="current" simple :total="total"
                @change="paginationChange" :pageSize="10" :hideOnSinglePage="false" />
        </a-card>






        <!-- <a-modal v-model:open="DeleteOpen" title="删除" @ok="">
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </a-modal> -->


        <a-modal title="确认删除该管理员吗" v-model:open="DeleteOpen" @ok="RemoveAnAdministrator(Delete.id)" okText="确认"
            cancelText="取消">
            <div style="padding: 50px 0;">
                <a-descriptions :column="2">
                    <a-descriptions-item label="管理员手机号">{{ Delete.mobile }}</a-descriptions-item>
                    <a-descriptions-item label="管理员职权">{{ Delete.roule_name }}</a-descriptions-item>
                </a-descriptions>
            </div>
        </a-modal>

        <a-modal title="编辑管理员" width="50%" v-model:open="EditingOpen" @ok="onFinish(Editing.id)" okText="确认"
            cancelText="取消">
            <div style="padding: 50px;">
                <a-form :model="Editing" layout="inline">
                    <a-form-item name="mobile" label="手机号" :rules="rules.mobile">
                        <a-input v-model:value="Editing.mobile" placeholder="请输入手机号" />
                    </a-form-item>
                    <a-form-item name="pwd" label="密码">
                        <a-input v-model:value="Editing.pwd" placeholder="请输入密码" />
                    </a-form-item>
                    <a-form-item label="权限">
                        <a-select ref="select" v-model:value="Editing.roule" style="width: 150px">
                            <a-select-option value="0" disabled>超级管理员</a-select-option>
                            <a-select-option value="1">制单员</a-select-option>
                            <a-select-option value="3">审核员</a-select-option>
                            <!-- <a-select-option value="2">审查员</a-select-option> -->
                            <a-select-option value="4">审查员</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>


    </div>
</template>

<script lang="ts" setup>
import { api_cw_add_account, api_index_get_account_list, api_cw_del_account } from '@/api/home'
import { Rule } from "ant-design-vue/es/form";
import { ref, reactive, onMounted } from "vue";

import { message, type TableColumnsType } from 'ant-design-vue';

import { useStore } from 'vuex';
const store = useStore();

let user = JSON.parse(store.state.user);

let FormData = reactive({
    mobile: '',
    pwd: '',
    roule: '1',
    openid: user.openid
});



onMounted(() => {
    finishFun('');
})


const rules: Record<string, Rule[]> = {
    mobile: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请确认手机号格', trigger: 'change' }],
    pwd: [{ required: true, message: '请输入密码', trigger: 'change', },
    { whitespace: true, required: true, message: '密码中不能带有空格！！' }]
};









let list: any = ref([]);
let total: any = ref(1)
//获取管理员列表
const finishFun = async (value: any) => {
    list.value = [];
    let up = {
        openid: user.openid,
        pageNo: current.value
    }
    let data: any = await api_index_get_account_list(up);
    if (data.error !== 0) {
        message.error('暂无数据！！！')
        return
    }
    total.value = data.total * 1;
    data.list.forEach((item: any) => {
        list.value.push(item);
    });

}


let current = ref<number>(1)

const paginationChange = (e: number) => {
    current.value = e;
    finishFun('');
}


const columns: TableColumnsType = [
    { title: '手机号', width: 100, dataIndex: 'mobile' },
    { title: '职权', width: 100, dataIndex: 'roule_name' },
    { title: '操作', width: 100, dataIndex: 'Controls' }
];

//新增|编辑  管理员
const onFinish = async (id: any) => {
    let up = {}
    if (!id) {
        up = {
            mobile: FormData.mobile,
            pwd: FormData.pwd,
            roule: FormData.roule,
            openid: user.openid,
        }
    } else {
        up = {
            pwd: Editing.value.pwd,
            mobile: Editing.value.mobile,
            roule: Editing.value.roule,
            openid: user.openid,
            id
        }
    }

    let data: any = await api_cw_add_account(up);
    if (data.error === 0) {
        finishFun('');
    }
    if (id) {
        EditingOpen.value = false;
    }
    FormData.mobile = '';
    FormData.pwd = '';
    FormData.roule = '1';
};



//删除管理员
const RemoveAnAdministrator = async (id: string) => {
    let up = {
        openid: user.openid,
        id
    }
    let data: any = await api_cw_del_account(up);
    console.log(data);
    DeleteOpen.value = false;
    if (data.error === 0) {
        finishFun('');
    }

}

let Delete = ref<any>(null);
let DeleteOpen = ref<boolean>(false);
//打开删除
const DeleteFun = (e: any) => {
    Delete.value = null;
    DeleteOpen.value = true;
    Delete.value = e;
}

let Editing = ref<any>(null);
let EditingOpen = ref<boolean>(false);
//编辑
const EditingFun = (e: any) => {
    EditingOpen.value = true;
    console.log(e);
    Editing.value = e;
}
</script>

<style lang="less" scoped>
.rolemanagement {
    // width: 100%;
    height: calc(100vh - 60px);
    overflow-y: auto;
}
</style>